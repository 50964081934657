@font-face{
	font-family: Proxima Nova;
	src: url('../_fonts/Proxima-Nova-Regular.eot');
	src: url('../_fonts/Proxima-Nova-Regular.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Regular.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Regular.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Regular.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
	font-family: Proxima Nova;
	src: url('../_fonts/Proxima-Nova-Semibold.eot');
	src: url('../_fonts/Proxima-Nova-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Semibold.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Semibold.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Semibold.svg#webfont') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face{
	font-family: Proxima Nova;
	src: url('../_fonts/Proxima-Nova-Bold.eot');
	src: url('../_fonts/Proxima-Nova-Bold.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Bold.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Bold.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Bold.svg#webfont') format('svg');
       font-weight: 700;
       font-style: normal;
}
@font-face{
	font-family: Proxima Nova Semibold;
	src: url('../_fonts/Proxima-Nova-Semibold.eot');
	src: url('../_fonts/Proxima-Nova-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Semibold.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Semibold.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Semibold.svg#webfont') format('svg');
    font-style: normal;
}

@font-face{
	font-family: Proxima Nova Bold;
	src: url('../_fonts/Proxima-Nova-Bold.eot');
	src: url('../_fonts/Proxima-Nova-Bold.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Bold.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Bold.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Bold.svg#webfont') format('svg');
}

@font-face{
	font-family: 'Proxima Nova Thin';
	src: url('../_fonts/Proxima-Nova-Thin.eot');
	src: url('../_fonts/Proxima-Nova-Thin.eot?#iefix') format('embedded-opentype'),
	     url('../_fonts/Proxima-Nova-Thin.woff') format('woff'),
	     url('../_fonts/Proxima-Nova-Thin.ttf') format('truetype'),
	     url('../_fonts/Proxima-Nova-Thin.svg#webfont') format('svg');
}

@font-face{
  font-family: 'Roboto';
  src: url('../_fonts/Roboto-Medium.ttf') format('truetype');
}
